import { getChainId, type waitForTransactionReceipt } from '@wagmi/core'
import type { Address } from 'viem'
import { useAccount } from 'wagmi'
import type { Erc20Token } from 'apollo/generated/graphqlClient'
import { WEB3_RESPONSE_ERROR_PREFIX, WEB3_USER_REJECT_TRANSACTION_CODE } from 'constants/common'
import { ETH, L1_LAYER_IDS } from 'constants/network'
import { useToast } from 'hooks/useToast'
import { wagmiConfig } from 'lib/wagmi'
import { useAssetContext } from 'providers/AssetProvider'
import { depositETH, withdrawETH, depositERC20, withdrawERC20 } from 'utils/bridge'

type UseBridgeProps = {
  amount: string
  fromId: number
  setIsSwitchNetworkModalOpen: (value: boolean) => void
  setPendingTransactionHash: React.Dispatch<React.SetStateAction<Address | null>>
  setBridgedTransaction: React.Dispatch<
    React.SetStateAction<Awaited<ReturnType<typeof waitForTransactionReceipt>> | null>
  >
  isPendingTransactionQuarantined: boolean
}

export const useBridge = ({
  amount,
  fromId,
  setIsSwitchNetworkModalOpen,
  setPendingTransactionHash,
  setBridgedTransaction,
  isPendingTransactionQuarantined,
}: UseBridgeProps) => {
  const { address } = useAccount()
  const toast = useToast()
  const { selectedAsset, refetchAssetsWithBalance } = useAssetContext()

  // https://ethereum.org/ml/developers/tutorials/optimism-std-bridge-annotated-code/.
  const handleBridge = async () => {
    const chainId = getChainId(wagmiConfig)

    if (chainId !== fromId) {
      setIsSwitchNetworkModalOpen(true)

      return
    }

    try {
      let tx: Awaited<ReturnType<typeof waitForTransactionReceipt>> | null = null

      if (selectedAsset.symbol === ETH.symbol) {
        tx = await (L1_LAYER_IDS.includes(fromId)
          ? depositETH({
              amount,
              account: address!, // User is connected at this point
              setPendingTransactionHash,
            })
          : withdrawETH({
              amount,
              account: address!, // User is connected at this point
              setPendingTransactionHash,
            }))
      } else {
        tx = await (L1_LAYER_IDS.includes(fromId)
          ? depositERC20({
              amount,
              selectedAsset: selectedAsset as Erc20Token,
              account: address!, // User is connected at this point
              setPendingTransactionHash,
            })
          : withdrawERC20({
              amount,
              selectedAsset: selectedAsset as Erc20Token,
              account: address!, // User is connected at this point
              setPendingTransactionHash,
            }))
      }

      if (!isPendingTransactionQuarantined) {
        setBridgedTransaction(tx)
      }

      await refetchAssetsWithBalance()
    } catch (error) {
      if (error instanceof Error) {
        if ('reason' in error && typeof error.reason === 'string') {
          toast({
            status: 'danger',
            message: error.reason.replace(WEB3_RESPONSE_ERROR_PREFIX, ''),
          })
          return
        }

        if (
          'cause' in error &&
          typeof error.cause === 'object' &&
          error.cause &&
          'cause' in error.cause &&
          typeof error.cause.cause === 'object' &&
          error.cause.cause &&
          'code' in error.cause.cause &&
          typeof error.cause.cause.code === 'number' &&
          error.cause.cause.code === WEB3_USER_REJECT_TRANSACTION_CODE
        ) {
          toast({
            status: 'danger',
            message: 'User rejected the request.',
          })
          return
        }

        toast({ status: 'danger', message: error.message })
      }
    }
  }

  return { handleBridge }
}
