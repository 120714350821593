import { readContract, waitForTransactionReceipt, writeContract } from '@wagmi/core'
import { ethers } from 'ethers'
import type { Address } from 'viem'
import type { Erc20Token } from 'apollo/generated/graphqlClient'
import { BRIDGE_MIN_GAS_LIMIT_ERC20 } from 'constants/network'
import { wagmiConfig } from 'lib/wagmi'
import { getSmartContracts } from 'utils/bridge/getSmartContracts'
import { isLidoWstEthToken } from 'utils/common'

export const depositERC20 = async ({
  amount,
  selectedAsset,
  account,
  setPendingTransactionHash,
}: {
  amount: string
  selectedAsset: Erc20Token
  account: Address
  setPendingTransactionHash: React.Dispatch<React.SetStateAction<Address | null>>
}) => {
  const isLidoToken = isLidoWstEthToken(selectedAsset.symbol)
  const { L1StandardBridge, L1StandardBridgeProxy } = getSmartContracts(selectedAsset.symbol)

  const bigIntAmount = BigInt(ethers.parseUnits(amount, selectedAsset.decimals_l1).toString())

  const abi = JSON.parse(selectedAsset.abi_l1)

  const allowance = (await readContract(wagmiConfig, {
    address: selectedAsset.contract_address_l1 as Address,
    abi,
    functionName: 'allowance',
    args: [account, L1StandardBridgeProxy.address],
  })) as bigint

  if (allowance < bigIntAmount) {
    const hash = await writeContract(wagmiConfig, {
      address: selectedAsset.contract_address_l1 as Address,
      abi,
      functionName: 'approve',
      args: [L1StandardBridgeProxy.address, bigIntAmount],
    })

    await waitForTransactionReceipt(wagmiConfig, { hash })
  }

  const depositERC20Hash = await writeContract(wagmiConfig, {
    address: L1StandardBridgeProxy.address,
    abi: L1StandardBridge.abi,
    functionName: isLidoToken ? 'depositERC20To' : 'bridgeERC20To',
    args: [
      selectedAsset.contract_address_l1 as Address,
      selectedAsset.contract_address_l2 as Address,
      account,
      bigIntAmount,
      BRIDGE_MIN_GAS_LIMIT_ERC20,
      '0x',
    ],
  })

  setPendingTransactionHash(depositERC20Hash)

  const tx = await waitForTransactionReceipt(wagmiConfig, { hash: depositERC20Hash })

  return tx
}
