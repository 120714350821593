import { DECIMALS } from 'constants/common'
import { env } from 'env.client'

export const getFormattedNumber = (value: string | number) =>
  Number(value).toLocaleString(undefined, { maximumFractionDigits: 20 })

export const weiToEth = (value: string | number, decimals?: string | number) =>
  Number(value) / 10 ** Number(decimals ?? DECIMALS)

export const calculateGasFees = ({ gasUsed = 0, gasPrice = 0 }) => weiToEth(gasUsed * gasPrice)

export const isLidoWstEthToken = (tokenName: string) =>
  (env.NEXT_PUBLIC_NETWORK === 'testnet' || env.NEXT_PUBLIC_NETWORK === 'mainnet') &&
  tokenName === 'wstETH'
