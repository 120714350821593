import { waitForTransactionReceipt, writeContract } from '@wagmi/core'
import { ethers } from 'ethers'
import type { Address } from 'viem'
import type { Erc20Token } from 'apollo/generated/graphqlClient'
import { BRIDGE_MIN_GAS_LIMIT_ERC20 } from 'constants/network'
import { wagmiConfig } from 'lib/wagmi'
import { getSmartContracts } from 'utils/bridge/getSmartContracts'
import { isLidoWstEthToken } from 'utils/common'

export const withdrawERC20 = async ({
  amount,
  selectedAsset,
  account,
  setPendingTransactionHash,
}: {
  amount: string
  selectedAsset: Erc20Token
  account: Address
  setPendingTransactionHash: React.Dispatch<React.SetStateAction<Address | null>>
}) => {
  const isLidoToken = isLidoWstEthToken(selectedAsset.symbol)
  const { L2StandardBridge, L2StandardBridgeProxy } = getSmartContracts(selectedAsset.symbol)

  const bigIntAmount = BigInt(ethers.parseUnits(amount, selectedAsset.decimals_l2).toString())

  const withdrawERC20Hash = await writeContract(wagmiConfig, {
    address: L2StandardBridgeProxy.address,
    abi: L2StandardBridge.abi,
    functionName: isLidoToken ? 'withdraw' : 'bridgeERC20To',
    args: isLidoToken
      ? [
          selectedAsset.contract_address_l2 as Address,
          bigIntAmount,
          BRIDGE_MIN_GAS_LIMIT_ERC20,
          '0x0000000000000000000000000000000000000000',
        ]
      : [
          selectedAsset.contract_address_l2 as Address,
          selectedAsset.contract_address_l1 as Address,
          account,
          bigIntAmount,
          BRIDGE_MIN_GAS_LIMIT_ERC20,
          '0x',
        ],
  })

  setPendingTransactionHash(withdrawERC20Hash)

  const tx = await waitForTransactionReceipt(wagmiConfig, { hash: withdrawERC20Hash })

  return tx
}
