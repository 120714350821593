import { Button, Center, Divider, Flex, Image, Text } from '@chakra-ui/react'
import type { QueryObserverResult, RefetchOptions } from '@tanstack/react-query'
import { getChainId, waitForTransactionReceipt, writeContract } from '@wagmi/core'
import { useTranslation } from 'next-i18next'
import React from 'react'
import { RiArrowRightLine } from 'react-icons/ri'
import { ContractFunctionExecutionError, defineChain, type Address } from 'viem'
import { getTransactionReceipt } from 'viem/actions'
import { getWithdrawals } from 'viem/op-stack'
import { useAccount } from 'wagmi'
import type { Erc20Token } from 'api/generated/resolversTypes'
import { ConnectButton } from 'components/ConnectButton'
import { ExternalLink } from 'components/ExternalLink'
import { InfoPopup } from 'components/InfoPopup'
import { Modal } from 'components/Modal'
import { DECIMALS, NOT_APPLICABLE } from 'constants/common'
import { TIMESTAMP_TOOLTIP_FORMAT } from 'constants/dayjs'
import { CHAINS, ETH } from 'constants/network'
import { env } from 'env.client'
import type {
  RecentTransactionsListItem as RecentTxItem,
  TransactionListItem as TxItem,
} from 'generated/reactQueryClient'
import { TransactionListItemExitStatus as TxItemExitStatus } from 'generated/reactQueryClient'
import { useThemeColors } from 'hooks/useThemeColors'
import { useToast } from 'hooks/useToast'
import dayjs from 'lib/dayjs'
import { publicClientL1, publicClientL2 } from 'lib/viem'
import { wagmiConfig } from 'lib/wagmi'
import { useAssetContext } from 'providers/AssetProvider'
import { getSmartContracts } from 'utils/bridge/getSmartContracts'
import { getFormattedNumber, weiToEth } from 'utils/common'
import { getAssetImageSrc } from 'utils/getAssetImageSrc'

type MessageRelayerModalProps = {
  data: TxItem | RecentTxItem | null
  isConnected: boolean
  isExecuted: boolean
  onClearData: () => void
  onExecutionChange: (txHash: string | null) => void
  onIncorrectNetwork: () => void
  onSuccessBridge: () => void
  refetch: (options?: RefetchOptions) => Promise<QueryObserverResult>
  txInExecution: string | null
}

export const MessageRelayerModal = ({
  data,
  isConnected,
  isExecuted,
  onClearData,
  onExecutionChange,
  onIncorrectNetwork,
  onSuccessBridge,
  refetch,
  txInExecution,
}: MessageRelayerModalProps) => {
  const COLORS = useThemeColors()
  const { t } = useTranslation(['common'])
  const { address } = useAccount()
  const toast = useToast()
  const { assets } = useAssetContext()

  const handleExecute = async (tx: TxItem) => {
    const chainId = getChainId(wagmiConfig)

    if (chainId !== CHAINS.l1.id) {
      onIncorrectNetwork()

      return
    }

    try {
      onExecutionChange(tx.l2TxHash)

      // These values are always present as we get this value for current environment and not for custom token bridging.
      const { OptimismPortalProxy, OptimismPortal, DEFAULT_L1_CONTRACT_ADDRESSES } =
        getSmartContracts()

      if (tx.exitStatus === TxItemExitStatus.ready_to_prove) {
        const receipt = await getTransactionReceipt(publicClientL2, {
          hash: tx.l2TxHash as Address,
        })

        const [withdrawal] = getWithdrawals(receipt)
        const output = await publicClientL1.getL2Output({
          l2BlockNumber: receipt.blockNumber,
          // @ts-expect-error - Viem wrong typings
          targetChain: defineChain({
            ...CHAINS.l1,
            contracts: {
              // @ts-expect-error - Viem wrong typings
              l2OutputOracle: {
                [CHAINS.l1.id]: {
                  address: DEFAULT_L1_CONTRACT_ADDRESSES!.L2OutputOracle,
                },
              },
              portal: {
                [CHAINS.l1.id]: {
                  address: DEFAULT_L1_CONTRACT_ADDRESSES!.OptimismPortal,
                },
              },
            },
          }),
        })

        const args = await publicClientL2.buildProveWithdrawal({
          account: address!, // User is connected at this point
          output,
          withdrawal,
        })

        const proveWithdrawalTransactionHash = await writeContract(wagmiConfig, {
          address: OptimismPortalProxy!.address,
          abi: OptimismPortal!.abi,
          functionName: 'proveWithdrawalTransaction',
          args: [args.withdrawal, args.l2OutputIndex, args.outputRootProof, args.withdrawalProof],
        })

        await waitForTransactionReceipt(wagmiConfig, { hash: proveWithdrawalTransactionHash })
        toast({ status: 'success', message: t('common:success.ProvingSuccessful') })
      }

      if (tx.exitStatus === TxItemExitStatus.ready_to_relay) {
        const receipt = await getTransactionReceipt(publicClientL2, {
          hash: tx.l2TxHash as Address,
        })

        const [withdrawal] = getWithdrawals(receipt)

        const finalizeWithdrawalTransactionHash = await writeContract(wagmiConfig, {
          address: OptimismPortalProxy!.address,
          abi: OptimismPortal!.abi,
          functionName: 'finalizeWithdrawalTransaction',
          args: [withdrawal],
        })

        await waitForTransactionReceipt(wagmiConfig, { hash: finalizeWithdrawalTransactionHash })
        toast({ status: 'success', message: t('common:success.RelayingSuccessful') })
      }

      await refetch()

      onSuccessBridge()
    } catch (error) {
      if (error instanceof ContractFunctionExecutionError) {
        toast({ status: 'danger', message: error.shortMessage ?? error.message })
      }
    } finally {
      onExecutionChange(null)
    }
  }

  const getMessageRelayerFooterButton = (transactionItem: TxItem | null) => {
    if (isExecuted) {
      return (
        <Button
          size="big"
          rightIcon={<RiArrowRightLine />}
          onClick={() => {
            onClearData()
          }}
        >
          {t('common:messageRelayer.GoBackToTransactions')}
        </Button>
      )
    }

    if (isConnected) {
      return (
        <Button
          size="big"
          isLoading={Boolean(txInExecution)}
          rightIcon={<RiArrowRightLine />}
          onClick={async () => {
            if (transactionItem) {
              await handleExecute(transactionItem)
            }
          }}
        >
          {t('common:messageRelayer.Execute')}
        </Button>
      )
    }

    return <ConnectButton />
  }

  return (
    <Modal
      isOpen={Boolean(data)}
      title="L2 to L1 Message Relayer"
      onClose={() => {
        onClearData()
      }}
    >
      <Center
        p={5}
        borderRadius={10}
        bgColor={COLORS.grey07}
        border="1px solid"
        borderColor={COLORS.grey06}
      >
        <Flex flexDir="column" alignItems="center">
          {isExecuted && (
            <Text textAlign="center" mb={3} variant="text2regular" color={COLORS.grey02}>
              {t('common:messageRelayer.Sent')}:
            </Text>
          )}
          <Text mb={3} isTruncated variant="heading3medium" maxW={280}>
            {(isExecuted ? data?.l1TxHash : data?.l2TxHash) ?? 'Transaction hash not available'}
          </Text>
          {((isExecuted && data?.l1TxHash) || (!isExecuted && data?.l2TxHash)) && (
            <ExternalLink
              variant="secondary"
              label={t(
                `common:messageRelayer.${isExecuted ? 'GoToTransactionHash' : 'ViewTxnDetails'}`,
              )}
              href={
                isExecuted
                  ? `${env.NEXT_PUBLIC_L1_URL}/tx/${data?.l1TxHash}`
                  : `${env.NEXT_PUBLIC_BLOCK_EXPLORER_URL}/tx/${data?.l2TxHash}`
              }
            />
          )}
        </Flex>
      </Center>
      <Flex mt={6} justifyContent="space-between" alignItems="center">
        <Text variant="caption2medium" color={COLORS.grey02}>
          {t('common:messageRelayer.TokenName')}
        </Text>
        <Flex alignItems="center">
          <Image
            alt="Ethereum"
            src={
              data?.transfers?.[0]?.symbol
                ? getAssetImageSrc({
                    symbol: data.transfers[0].symbol,
                    contract_address_l1: (
                      assets.find(
                        (asset) => asset.symbol === data.transfers[0].symbol,
                      ) as Erc20Token
                    )?.contract_address_l1,
                  })
                : '/assets/eth2.svg'
            }
          />
          <Text ml={1} variant="text3regular">
            {data?.transfers?.[0]?.symbol ?? ETH.symbol}
          </Text>
        </Flex>
      </Flex>
      <Divider my={3} />
      <Flex justifyContent="space-between" alignItems="center">
        <Text variant="caption2medium" color={COLORS.grey02}>
          {t('common:messageRelayer.Value')}
        </Text>
        <Text ml={1} variant="text3regular" color={COLORS.grey02}>
          {getFormattedNumber(
            weiToEth(
              data?.transfers?.[0]?.wei ?? data?.value ?? 0,
              data?.transfers?.[0]?.decimals ?? DECIMALS,
            ),
          )}{' '}
          {data?.transfers?.[0]?.symbol ?? ETH.symbol}
        </Text>
      </Flex>
      <Divider my={3} />
      <Flex justifyContent="space-between" alignItems="center">
        <Text variant="caption2medium" color={COLORS.grey02}>
          {t('common:messageRelayer.Timestamp')}
        </Text>
        <Flex ml={1} gap={2} alignItems="center">
          <Text variant="text3regular" color={COLORS.grey02}>
            {data?.timestamp ? dayjs.unix(data.timestamp).fromNow() : NOT_APPLICABLE}
          </Text>
          {!!data?.timestamp && (
            <InfoPopup title={`${t('common:messageRelayer.Timestamp')}:`}>
              {dayjs.unix(data.timestamp).format(TIMESTAMP_TOOLTIP_FORMAT)}
            </InfoPopup>
          )}
        </Flex>
      </Flex>
      <Flex w="100%" flexDir="column" pt={5}>
        {getMessageRelayerFooterButton(data)}
      </Flex>
    </Modal>
  )
}
